<template>
   <div class="notice_popup human">
        <div class="close">
            <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
        </div>
        <div class="tit">프로젝트 삭제하기</div>
        <div class="member prj">
            <span class="name"> &#039;{{this.param.projectNm}}&#039; 를</span><br/>정말로 삭제 하시겠습니까?
        </div>
        <template>            
            <div class="select-btn">
                <div class="btn" @click="$emit('close', false)">취소</div>
                <div class="btn" @click="$emit('close', true)">예</div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: { param:Object },
    
    data() {
        return {
           
        };
    }, 
    methods: {
        
    },
};
</script>
